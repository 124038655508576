import {onMounted, provide} from 'vue';
import {useRoute, useDataTracking} from '#imports';

/**
 * Sends a `website_page_viewed` event to /api/t/event endpoint.
 *
 * @param {string} pageCategory - Optional. The category of the page.
 * @param {string} pageSubCategory - Optional. The sub category of the page.
 *
 * @usage
 *
 * ```ts
 *  import {useWebsitePageView} from '#imports';
 *
 *  useWebsitePageView('homepage');
 * ```
 */

export function useWebsitePageView(pageCategory?: string, pageSubCategory?: string) {
    const route = useRoute();
    const {track, extractWebAttributionProperties} = useDataTracking();

    function getSourceComponent() {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('source_component') || null;
    }

    onMounted(() => {
        const eventProperties = {
            url: route.path,
            page_title: document.title,
            page_category: pageCategory,
            page_subcategory: pageSubCategory,
        };

        const userProperties = {
            ...extractWebAttributionProperties(),
            referrer: document.referrer || null,
            source_component: getSourceComponent(),
            screen_resolution: `${window.screen.width}x${window.screen.height}`,
        };

        track('website_page_viewed', eventProperties, userProperties);
    });

    provide('pageCategory', pageCategory);

    return {};
}
